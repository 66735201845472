import Pusher from "pusher-js";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SpinnerProvider } from "./Components/Spinner/SpinnerContext";
import withRoot from "./Hoc/withRoot";
import ChatBubble from "./Modules/LiveChat/ChatBubble";
import MainHeader from "./Modules/Shared/Components/Header/MainHeader";
import { BasicModal } from "./Modules/Shared/Components/Modal/BasicModal";
import BasicModalInstance from "./Modules/Shared/Components/Modal/BasicModalInstance/BasicModalInstance";
import CookiesModal from "./Modules/Shared/Components/Modal/CookiesModal/CookiesModal";
import Footer from "./Modules/Shared/Components/footer/footer";
import { ModalContextProvider } from "./Providers/ModalProvider";
import { PusherProvider } from "./Providers/PusherProvider";
import { getlivechatSettings } from "./Redux/Actions/livechat/livechat.action";
import { getPreferences } from "./Services/api/Preferences/PreferencesService";
import { getListType } from "./Services/api/TypeFile/TypeFileProvider";
import { AuthProvider } from "./Services/api/auth/AuthContext";
import { FooterContext } from "./Services/api/toutorials/FooterContext";

// Pusher.logToConsole = true;
function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const footerCtx = useContext(FooterContext);
  const query = new URLSearchParams(useLocation().search);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const cookiesModalRef = useRef();

  const [livechatSetting, setLivechatSetting] = useState({});
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [mounted, setMounted] = useState(false);
  const { livechat_setting } = useSelector((state) => state.livechatReducer);

  const cookies = localStorage.getItem("cookies");

  const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
    appId: process.env.REACT_APP_PUSHER_APP_ID,
    key: process.env.REACT_APP_PUSHER_APP_KEY,
    secret: process.env.REACT_APP_PUSHER_APP_SECRET,
    cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
    encrypted: true,
    enabledTransports: ["ws"],
    disabledTransports: ["flash", "xhr_polling", "xhr_streaming"],
    forceTLS: true,
    useTLS: true,
    channelAuthorization: {
      endpoint: process.env.REACT_APP_PUSHER_AUTH_URL,
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Access-Control-Allow-Methods": "DELETE, POST, GET, PUT, OPTIONS",
      },
    },
  });

  const fetchPreferences = () => {
    const myPromise = new Promise((resolve, reject) => {
      getPreferences().then(
        (res) => {
          if (res.status == 200 || res.status == 201) {
            const fetchedSettings = res.data.setting;
            const toBeSavedObject = {
              chat_service: fetchedSettings.chat_service ? true : false,
              support_tickets_service: fetchedSettings.support_tickets_service
                ? true
                : false,
              welcome_message_service: fetchedSettings.welcome_message_service
                ? true
                : false,
              support_tickets_faq_service:
                fetchedSettings.support_tickets_faq_service ? true : false,
              courses_faq_service: fetchedSettings.courses_faq_service
                ? true
                : false,
            };
            localStorage.setItem(
              "system_preferences",
              JSON.stringify(toBeSavedObject)
            );
          }
          resolve();
        },
        (error) => {
          reject();
        }
      );
    });

    return myPromise;
  };
  // Save File Type:
  const fetchFileType_Admin = () => {
    const myPromise = new Promise((resolve, reject) => {
      getListType().then(
        (res) => {
          if (res.status == 200 || res.status == 201) {
            const fetchedTypes = res.data.data;
            localStorage.setItem(
              "system_files_types",
              JSON.stringify(fetchedTypes)
            );
          }
          resolve();
        },
        (error) => {
          reject();
        }
      );
    });

    return myPromise;
  };

  useEffect(() => {
    if (localStorage.getItem("userId")) {
      fetchPreferences();
      fetchFileType_Admin();
    }
    dispatch(getlivechatSettings());
  }, []);

  const fetchSetting = () => {
    if (livechat_setting?.status === 200) {
      setLivechatSetting(livechat_setting.data.setting.chat_allowed);
    }
  };

  useEffect(() => {
    fetchSetting();
  }, [livechat_setting]);

  // hide the html and check if there an iframe on top
  useEffect(() => {
    const html = document.querySelector("html");
    html.style.display = "none";

    if (window.top === window.self) {
      html.style.display = "block";
    } else {
      // html.style.display = "block";
      window.top.location = window.self.location;
    }
  }, [window.top, window.self]);

  useEffect(() => {
    const cspMeta = document.createElement("meta");
    cspMeta.httpEquiv = "Content-Security-Policy";
    cspMeta.content = `frame-src 'self' ${process.env.REACT_APP_SERVER_PATH} ${process.env.REACT_APP_FRONT_BASE_URL} ${process.env.REACT_APP_EXHIBITION_BACKEND_URL} ${process.env.REACT_APP_EXHIBITION_BUILD_URL} https://tebx-migrate-be.tajahdev.dev/ https://www.google.com https://www.youtube.com https://drive.google.com/ blob:`;
    document.head.appendChild(cspMeta);

    return () => {
      document.head.removeChild(cspMeta);
    };
  }, []);

  const handleOpenCookiesModal = () => {
    setShowCookiesModal(true);
    // cookiesModalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleCloseCookiesModal = () => {
    setShowCookiesModal(false);
    // cookiesModalRef.current.dismissModal();
    document.body.style.overflow = "";
  };

  useEffect(() => {
    if (!cookies) {
      handleOpenCookiesModal();
    }
  }, [mounted]);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <SpinnerProvider>
      <ScrollToTop />
      <AuthProvider>
        <PusherProvider instance={pusher}>
          <ModalContextProvider>
            <BasicModalInstance></BasicModalInstance>
            <div
              style={{
                minHeight: "calc(100vh - 130.6px)",
              }}
            >
              {localStorage.getItem("token") && (
                <div
                  style={{
                    height: "40px",
                    backgroundColor: "#24b3b9",
                    padding: "1rem 2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                  }}
                >
                  <p>{t("welcome_message")}</p>
                </div>
              )}
              <MainHeader />
              <>
                {showCookiesModal ? (
                  <CookiesModal handleClose={handleCloseCookiesModal} />
                ) : null}
              </>
            </div>
            {livechatSetting ? null : <ChatBubble></ChatBubble>}
            {footerCtx.footer && <Footer query={query.get("method_block")} />}
          </ModalContextProvider>
        </PusherProvider>
      </AuthProvider>
      <ToastContainer />
    </SpinnerProvider>
  );
}

export default withRoot(App);
